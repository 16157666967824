import React, { useState } from "react";
import dayjs from "dayjs";
import Button from "../../Button";
import Card from "../../Card";
import CalendlyModal from "../../CalendlyModal";
import { CALENDLY_URLS } from "../../CalendlyModal/constants";
import ReportBlock from "../../FinancialReports/ReportBlock";
import { getDateRangeForAnnual } from "../../CustomDateRangePicker/ShortcutsButton/Dropdown/utils";
import PageTitle from "../../PageTitle";
import Video from "../../Video";
import styles from "./styles.module.css";

const mockData = [
    [
        {
            name: "Cash Inflows",
            record_category: "type",
        },
        {
            amount_cents: 508298899,
            ledger_id: "Lo1Chg7AYNnn6PkqA2EfKe",
            name: "Sales Revenue",
            record_category: "subtype",
        },
        {
            amount_cents: 508298899,
            ledger_id: "Lo1Chg7AYNnn6PkqA2EfKe",
            name: "Sales Revenue",
            record_category: "subtype",
        },
        {
            amount_cents: 835684621,
            name: "Total Cash Inflows",
            record_category: "type",
        },
        {
            name: "Cash Inflows",
            record_category: "type",
        },
    ],
    [
        {
            name: "Cash Outflows",
            record_category: "type",
        },
        {
            amount_cents: -1017412,
            ledger_id: "4YejfXpKvDH3QgYrREWn82",
            name: "Cost Of Goods Sold",
            record_category: "subtype",
        },
        {
            amount_cents: -1017412,
            ledger_id: "4YejfXpKvDH3QgYrREWn82",
            name: "Cost Of Goods Sold",
            record_category: "subtype",
        },
        {
            amount_cents: -1017412,
            ledger_id: "4YejfXpKvDH3QgYrREWn82",
            name: "Cost Of Goods Sold",
            record_category: "subtype",
        },
        {
            amount_cents: -821785948,
            name: "Total Cash Outflows",
            record_category: "type",
        },
        {
            name: "Cash Outflows",
            record_category: "type",
        },
        {
            amount_cents: -1017412,
            ledger_id: "4YejfXpKvDH3QgYrREWn82",
            name: "Cost Of Goods Sold",
            record_category: "subtype",
        },
        {
            name: "Cash Outflows",
            record_category: "type",
        },
    ],
    [
        {
            amount_cents: 13898673,
            name: "Net Cash Flow",
            record_category: "type",
        },
    ],
];

const FLYCHAIN_DEMO_URL =
    "https://www.loom.com/embed/fb48afdb1e0840c4b4bfbd7d68e963c2";

function Content() {
    const now = dayjs();
    const dateRange = getDateRangeForAnnual(now.year(), now);
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    return (
        <div className={styles.container}>
            {mockData.map((reportLines, index) => (
                <div className={styles.reportBlockContainer} key={index}>
                    <ReportBlock
                        reportLines={reportLines}
                        maxRecordCategory="subtype"
                        dateRange={dateRange}
                    />
                </div>
            ))}
            <div className={styles.overlay}>
                <div className={styles.cardContainer}>
                    <Card showTab tabText="Schedule Demo" tabColor="green">
                        <div>
                            <div className={styles.pageTitleContainer}>
                                <PageTitle>
                                    The Financial Operating System for Your
                                    Practice
                                </PageTitle>
                            </div>
                            <div className={styles.subtitle}>
                                Optimize the financial health of your practice
                                with our suite of financial tools. Let us handle
                                your financial workflows so you can spend less
                                time in spreadsheets and more time with
                                patients.
                            </div>
                            <div className={styles.videoContainer}>
                                <div className={styles.video}>
                                    <Video
                                        src={FLYCHAIN_DEMO_URL}
                                        title="Flychain Demo"
                                    />
                                </div>
                            </div>
                            <div className={styles.buttonContainer}>
                                <Button
                                    color="primary"
                                    onClick={() => setShowCalendlyModal(true)}
                                >
                                    Schedule a Demo
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            {showCalendlyModal && (
                <CalendlyModal
                    onClose={() => setShowCalendlyModal(false)}
                    calendlyUrl={CALENDLY_URLS.FLYCHAIN_TEAM}
                />
            )}
        </div>
    );
}

export default Content;
