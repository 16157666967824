import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders, blobResponseHandler } from "../apiUtils";
import {
    transformGetOrCreateUserResponse,
    transformGetOfferResponse,
    getPlaidLinkTokenUrl,
    getTopExpensesGraphUrl,
    getRevenueByCategoryUrl,
    getFinancialReportsSummaryUrl,
    getIncomeStatementUrl,
    getBalanceSheetUrl,
    getCashFlowReportUrl,
    getLedgerLinesUrl,
    getDownloadIncomeStatementUrl,
    getDownloadBalanceSheetUrl,
    getDownloadCashFlowReportUrl,
    getDownloadExpensesByCategoryUrl,
    getMonthlyLedgerTotalsUrl,
} from "./utils";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders,
});

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery,
    tagTypes: ["provider", "plaidItems"],
    endpoints: (builder) => ({
        getOrCreateUser: builder.query({
            query: () => "/v1/user",
            transformResponse: (data) => transformGetOrCreateUserResponse(data),
        }),
        getProvider: builder.query({
            query: () => "/v1/provider",
            providesTags: ["provider"],
        }),
        upsertProvider: builder.mutation({
            query: (body) => ({
                url: "/v1/provider",
                method: "POST",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
        getPlaidLinkToken: builder.query({
            query: (args) => getPlaidLinkTokenUrl(args),
        }),
        getPlaidItems: builder.query({
            query: () => "/v1/plaid/items",
            providesTags: ["plaidItems"],
        }),
        savePlaidItems: builder.mutation({
            query: () => ({
                url: "/v1/plaid/items",
                method: "POST",
            }),
            invalidatesTags: ["provider"],
        }),
        getPlaidItemsWithOnlyCheckingAccounts: builder.query({
            query: () => "/v1/plaid/items/checking_accounts",
            /*
                getPlaidItemsWithOnlyCheckingAccounts uses a separate endpoint from getPlaidItems so the results returned
                will be stored in separate caches. See the link below for more detail on the behavior of providesTags and
                invalidatesTags and how tags affect cached data:
                https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
            */
            providesTags: ["plaidItems"],
        }),
        addPlaidItem: builder.mutation({
            query: (body) => ({
                url: "/v1/plaid/link",
                method: "POST",
                body,
            }),
            invalidatesTags: ["plaidItems"],
        }),
        updatePlaidItem: builder.mutation({
            query: (body) => ({
                url: "/v1/plaid/item",
                method: "PUT",
                body,
            }),
            invalidatesTags: ["plaidItems"],
        }),
        addProviderBankAccount: builder.mutation({
            query: (body) => ({
                url: "/v1/provider/bank_account",
                method: "POST",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
        getTerms: builder.query({
            query: (drawId) => `/v1/terms/${drawId}`,
        }),
        acceptTerms: builder.mutation({
            query: (body) => ({
                url: "/v1/terms",
                method: "POST",
                body,
            }),
        }),
        getOffer: builder.query({
            query: () => `/v1/provider/offer`,
            transformResponse: (data) => transformGetOfferResponse(data),
        }),
        updateOffer: builder.mutation({
            query: (body) => ({
                url: "/v1/provider/offer",
                method: "PUT",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
        getDataForAdvancedPaymentOnClaims: builder.query({
            query: () => "/v1/platform/capital",
        }),
        getLineForAdvancedPaymentOnClaims: builder.query({
            query: () => "/v1/platform/capital/line",
        }),
        getDataForNetCashFlowGraph: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/cash_flow_graph?business_entity_id=${businessEntityId}`,
        }),
        getDataForCashBalanceGraph: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/cash_balance_graph?business_entity_id=${businessEntityId}`,
        }),
        getDataForNetProfitGraph: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/net_profit_graph?business_entity_id=${businessEntityId}`,
        }),
        getDataForTopExpensesGraph: builder.query({
            query: (args) => getTopExpensesGraphUrl(args),
        }),
        getDataForFinancialReportsSummary: builder.query({
            query: (args) => getFinancialReportsSummaryUrl(args),
        }),
        getDataForIncomeStatement: builder.query({
            query: (args) => getIncomeStatementUrl(args),
        }),
        getDataForBalanceSheet: builder.query({
            query: (args) => getBalanceSheetUrl(args),
        }),
        getDataForCashFlowReport: builder.query({
            query: (args) => getCashFlowReportUrl(args),
        }),
        getDataForLedgerLines: builder.query({
            query: (args) => getLedgerLinesUrl(args),
        }),
        getSavings: builder.query({
            query: () => `/v1/platform/savings`,
        }),
        getPlatformSubscription: builder.query({
            query: () => "/v1/platform/subscription",
        }),
        getBusinessEntities: builder.query({
            query: () => "/v1/provider/business_entities",
        }),
        getFinancialReportsValidUpUntil: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/valid_up_until?business_entity_id=${businessEntityId}`,
        }),
        downloadIncomeStatement: builder.query({
            query: (args) => ({
                url: getDownloadIncomeStatementUrl(args),
                responseHandler: blobResponseHandler,
            }),
        }),
        downloadBalanceSheet: builder.query({
            query: (args) => ({
                url: getDownloadBalanceSheetUrl(args),
                responseHandler: blobResponseHandler,
            }),
        }),
        downloadCashFlowReport: builder.query({
            query: (args) => ({
                url: getDownloadCashFlowReportUrl(args),
                responseHandler: blobResponseHandler,
            }),
        }),
        downloadExpensesByCategory: builder.query({
            query: (args) => ({
                url: getDownloadExpensesByCategoryUrl(args),
                responseHandler: blobResponseHandler,
            }),
        }),
        getExpensesByMerchant: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/expenses_by_merchant?business_entity_id=${businessEntityId}`,
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getRevenueByCategory: builder.query({
            query: (args) => getRevenueByCategoryUrl(args),
        }),
        getMonthlyRevenueByCategory: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/revenue_by_category/stacked_graph?business_entity_id=${businessEntityId}`,
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getMonthlyExpensesByCategory: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/expenses_by_category/stacked_graph?business_entity_id=${businessEntityId}`,
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getMonthlyLedgerTotals: builder.query({
            query: (args) => getMonthlyLedgerTotalsUrl(args),
        }),
        getPartner: builder.query({
            query: () => "/v1/partner",
        }),
        getValuation: builder.query({
            query: () => "/v1/platform/valuation",
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
    }),
});

export const {
    useGetOrCreateUserQuery,
    useGetProviderQuery,
    useUpsertProviderMutation,
    useLazyGetPlaidLinkTokenQuery,
    useGetPlaidItemsQuery,
    useSavePlaidItemsMutation,
    useGetPlaidItemsWithOnlyCheckingAccountsQuery,
    useAddPlaidItemMutation,
    useUpdatePlaidItemMutation,
    useAddProviderBankAccountMutation,
    useGetTermsQuery,
    useAcceptTermsMutation,
    useGetOfferQuery,
    useUpdateOfferMutation,
    useGetDataForAdvancedPaymentOnClaimsQuery,
    useGetLineForAdvancedPaymentOnClaimsQuery,
    useGetDataForNetCashFlowGraphQuery,
    useGetDataForCashBalanceGraphQuery,
    useGetDataForNetProfitGraphQuery,
    useGetDataForTopExpensesGraphQuery,
    useGetRevenueByCategoryQuery,
    useGetDataForFinancialReportsSummaryQuery,
    useGetDataForIncomeStatementQuery,
    useGetDataForBalanceSheetQuery,
    useGetDataForCashFlowReportQuery,
    useGetDataForLedgerLinesQuery,
    useGetSavingsQuery,
    useGetPlatformSubscriptionQuery,
    useGetBusinessEntitiesQuery,
    useGetFinancialReportsValidUpUntilQuery,
    useLazyDownloadIncomeStatementQuery,
    useLazyDownloadBalanceSheetQuery,
    useLazyDownloadCashFlowReportQuery,
    useLazyDownloadExpensesByCategoryQuery,
    useGetExpensesByMerchantQuery,
    useGetMonthlyRevenueByCategoryQuery,
    useGetMonthlyExpensesByCategoryQuery,
    useGetMonthlyLedgerTotalsQuery,
    useGetPartnerQuery,
    useGetValuationQuery,
} = apiSlice;

export const { getBusinessEntities, getFinancialReportsValidUpUntil } =
    apiSlice.endpoints;
