import React from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import SectionTitle from "../../SectionTitle";
import CustomDateRangePicker from "../../CustomDateRangePicker";
import ReportBlock from "../ReportBlock";
import ExportReportButton from "../ExportReportButton";
import {
    updateDateRange,
    serializeDateRange,
    selectDateRange,
} from "../../../redux/slices/dateRange";
import useGetDataForIncomeStatementQuery from "../../../hooks/queriesAndMutations/useGetDataForIncomeStatementQuery";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import NetProfitGraph from "./NetProfitGraph";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import Summary from "./Summary";
import styles from "./styles.module.css";

function IncomeStatement() {
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const { data: incomeStatementData } = useGetDataForIncomeStatementQuery();

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();
    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    return (
        <div className={styles.container}>
            <BusinessEntitySelect />
            <div className={styles.validUpUntilContainer}>
                <ValidUpUntil />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Net Profit by Month</SectionTitle>
            </div>
            <div className={styles.netProfitGraphContainer}>
                <NetProfitGraph />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Income Statement Report</SectionTitle>
            </div>
            <div className={styles.controlsContainer}>
                <CustomDateRangePicker
                    dateRange={dateRange}
                    onChange={setDateRange}
                    maxDate={maxDate}
                />
                <ExportReportButton />
            </div>
            <div className={styles.summaryContainer}>
                <Summary />
            </div>
            {incomeStatementData
                ? incomeStatementData.report_blocks.map(
                      (reportLines, index) => (
                          <div
                              className={styles.reportBlockContainer}
                              key={index}
                          >
                              <ReportBlock
                                  reportLines={reportLines}
                                  maxRecordCategory="subtype"
                              />
                          </div>
                      )
                  )
                : null}
        </div>
    );
}

export default IncomeStatement;
