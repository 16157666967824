import React from "react";
import { Outlet, useParams } from "react-router-dom";
import Content from "../../Content";
import SideBar from "../../SideBar";
import NavigationTab from "../../SideBar/NavigationTab";
import CapitalSvg from "../../../assets/Capital";
import FinancialReportsSvg from "../../../assets/FinancialReports";
import SavingsSvg from "../../../assets/Savings";
import ValuationSvg from "../../../assets/Valuation";
import TaxesSvg from "../../../assets/Taxes";
import WalletSvg from "../../../assets/Wallet";
import ConnectionsSvg from "../../../assets/Connections";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import { financialReportsSubtabs } from "../../SideBar/constants";
import useGetCapitalSubtabs from "../../../hooks/useGetCapitalSubtabs";

function SideBarAndContent() {
    let { businessEntityId } = useParams();

    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    businessEntityId =
        businessEntityId || (businessEntities && businessEntities[0].id);

    const capitalSubtabs = useGetCapitalSubtabs();

    if (!businessEntityId || !capitalSubtabs) return null;

    return (
        <>
            <SideBar>
                <NavigationTab
                    label="Financials"
                    path={`/businessEntity/${businessEntityId}/financialReports`}
                    icon={<FinancialReportsSvg fill="white" />}
                    subtabs={financialReportsSubtabs}
                />
                <NavigationTab
                    label="Capital"
                    path="/capital"
                    icon={<CapitalSvg fill="white" />}
                    subtabs={capitalSubtabs}
                />
                <NavigationTab
                    label="Taxes"
                    path="/taxes"
                    icon={<TaxesSvg fill="white" />}
                />
                <NavigationTab
                    label="Valuation"
                    path="/valuation"
                    icon={<ValuationSvg fill="white" />}
                />
                <NavigationTab
                    label="Treasury"
                    path="/treasury"
                    icon={<WalletSvg fill="white" />}
                />
                <NavigationTab
                    label="Savings"
                    path="/savings"
                    icon={<SavingsSvg fill="white" />}
                />
                <NavigationTab
                    label="Connections"
                    path="/connections"
                    icon={<ConnectionsSvg fill="white" />}
                />
            </SideBar>
            <Content>
                <Outlet />
            </Content>
        </>
    );
}

export default SideBarAndContent;
